import React, { useState } from 'react';
import { Box, TextField, Button } from '@mui/material';

export default function Inscribirse() {
    const [mail, setMail] = useState('');

    return (
        <Box sx={{
            display: "flex",
            gap: "20px",
            alignItems: "center"
        }}>
            <TextField
                label="Correo electrónico"
                fullWidth
                value={mail}
                onChange={e => setMail(e.target.value)}
            />
            <Button
                variant="contained"
                sx={{
                    paddingX: "40px",
                    paddingY: "10px",
                    background: "#CDAE4E",
                    "&:hover": {
                        background: "#CDAE4E",
                    }

                }}
            >
                Inscribirse
            </Button>
        </Box>
    );
}