import { Box, Button, Typography } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';

import aiImage from './image.png';

export default function Layout() {
    const navigate = useNavigate();

    return (
        <Box>
            <Box sx={{
                height: "10vh",
                width: "100%",

                display: "flex",
                gap: "40px",
                paddingX: "20px",
                boxSizing: "border-box",
                alignItems: "center",
                justifyContent: "space-between",
                position: "fixed",
                backdropFilter: "blur(10px)",
                background: "rgba(255, 255, 255, 0.4)",
                top: 0,
                left: 0,
            }}>
                <Typography sx={{
                    fontSize: "28px",
                    fontWeight: "bold",
                    cursor: "pointer"
                }}
                    onClick={() => navigate('/')}
                >
                    Tooli
                </Typography>
                <img src={aiImage} alt="AI" style={{
                    width: "45px",
                    height: "45px",
                    objectFit: "cover",
                    borderRadius: "50%",
                    cursor: "pointer"
                }}
                    onClick={() => navigate('/preguntas')}
                />
            </Box>
            <Box sx={{
                marginTop: "10vh",
            }}>
                <Outlet />
            </Box>
        </Box>
    )
}