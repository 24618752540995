import './App.css';
import { Grid, Box, Typography, TextField, Button } from '@mui/material';
import Home from './pages/Home/Home';

import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'
import Preguntas from './pages/Preguntas';
import Layout from './layouts/Layout';

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/preguntas" element={<Preguntas />} />
        </Route>
      </Routes>
    </Router>
  )
}

export default App;
