import { useEffect, useState } from 'react';
import useFetch, { host } from '..//utils/Fetchs';

import { Box, Typography, TextField, Button } from '@mui/material';

export default function Preguntas() {
    const [preguntas, setPreguntas] = useState([]);
    const [pregunta, setPregunta] = useState('');
    const [loading, setLoading] = useState(false);

    const { getFetch, postFetch } = useFetch();

    useEffect(() => {
        getFetch(`${host}/preguntas/`)
            .then(data => setPreguntas(data.preguntas))
    }, []);

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: "nowrap",
                gap: 4,
                boxSizing: "border-box",
                marginTop: "10vh",
                marginBottom: "20vh",
            }}>
                {preguntas.map(pregunta => (
                    <Box key={pregunta.id} sx={{
                        boxShadow: 1,
                        width: '90%',
                        maxWidth: 800,
                        padding: 2,
                        gap: 2,
                    }}>
                        <Typography variant="h4">{pregunta.pregunta}</Typography>
                        <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>{pregunta.respuesta}</Typography>
                    </Box>
                ))}
            </Box>
            <Box sx={{
                height: "15vh",
                width: "100%",
                display: "flex",
                gap: "40px",
                paddingX: "40px",
                boxSizing: "border-box",
                alignItems: "center",
                padding: 2,
                position: "fixed",
                background: "white",
                bottom: 0,
                left: 0,
                boxShadow: 8,
            }}>
                <TextField
                    label="Pregunta"
                    variant="outlined"
                    fullWidth
                    value={pregunta}
                    onChange={e => setPregunta(e.target.value)}

                />
                <Button variant="contained"
                    sx={{
                        height: "fit-content",
                        background: "#CDAE4E",

                        "&:hover": {
                            background: "#CDAE4E",
                        }
                    }}
                    disabled={loading}

                    onClick={() => {
                        setLoading(true);
                        postFetch(`${host}/preguntas/`, { pregunta })
                            .then(data => setPreguntas([...preguntas, data]))
                            .finally(() => {
                                setLoading(false);
                                setPregunta('');
                            });
                    }}>Preguntar</Button>
            </Box>
        </Box>
    );
}