import { Box, Typography } from "@mui/material";
import Inscribirse from "./Inscribirse";

export default function Home() {
    function Imagen({ src, alt, left = true }) {
        return (
            <img
                src={src}
                alt={alt}
                style={{
                    width: "100%",
                    height: "400px",
                    objectFit: "cover",
                    borderRadius: left ? "20px 0 0 20px" : "0 20px 20px 0"
                }} />
        );
    }

    function Bloque({ src, alt, titulo, texto, left = true }) {
        return (
            <Box sx={{
                width: "100%",
                display: "flex",
                marginTop: "40px",
                flexWrap: "wrap",
            }}>
                {!left && <Box sx={{
                    width: { xs: "100%", md: "50%" }
                }}>
                    <Imagen
                        src={src}
                        alt={alt}
                        left={left}
                    />
                </Box>}
                <Box sx={{
                    width: { xs: "100%", md: "50%" },
                    display: "flex",
                    flexDirection: "column",
                    paddingX: { xs: "20px", md: "80px" },
                    justifyContent: "center",
                    boxSizing: "border-box",
                }}>
                    <Typography sx={{
                        fontSize: "38px",
                        fontWeight: "bold",
                    }}>
                        {titulo}
                    </Typography>
                    <Typography sx={{
                        fontSize: "24px",
                        color: "gray",
                    }}>
                        {texto}
                    </Typography>
                </Box>
                {left &&
                    <Box sx={{
                        width: { xs: "100%", md: "50%" }
                    }}>
                        <Imagen
                            src={src}
                            alt={alt}
                            left={left}
                        />
                    </Box>
                }
            </Box>
        );
    }


    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "20vh",
        }}>
            <Box sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
            }}>
                <Box sx={{
                    width: { xs: "100%", md: "50%" },
                    display: "flex",
                    flexDirection: "column",
                    paddingX: { xs: "20px", md: "80px" },
                    gap: "20px",
                    boxSizing: "border-box",
                }}>
                    <Typography sx={{
                        fontSize: "60px",
                        fontWeight: "bold",
                        lineHeight: "1",
                    }}>
                        Capacítate y crece tu negocio
                    </Typography>
                    <Typography sx={{
                        fontSize: "24px",
                        color: "gray",
                    }}>
                        Tooli ofrece capacitación especializada en ventas, marketing y estrategias de promoción para propietarios de pequeños negocios tradicionales, como comercios minoristas y ferreterías. Mejora tus habilidades y transforma tu negocio.
                    </Typography>
                    <Inscribirse />
                </Box>
                <Box sx={{
                    width: { xs: "100%", md: "50%" },
                    display: "flex",
                    alignItems: "center",
                    marginTop: { xs: "40px", md: "0" },
                }}>
                    <Imagen
                        src="https://images.unsplash.com/photo-1695654398992-125945541043?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wzMzczODV8MHwxfHJhbmRvbXx8fHx8fHx8fDE3MjE3NDM4OTh8&ixlib=rb-4.0.3&q=80&w=1080"
                        alt="Foto"
                    />
                </Box>
            </Box>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: "30px",
                marginTop: "100px",
            }}>
                <Typography sx={{
                    fontSize: "40px",
                    fontWeight: "bold",
                    textAlign: "center",
                }}>
                    Mejora tus habilidades
                </Typography>
                <Typography sx={{
                    fontSize: "24px",
                    textAlign: "center",
                }}>
                    Capacitación especializada para crecer
                </Typography>

                <Bloque
                    src="https://images.unsplash.com/photo-1695654397943-e0686936d6bd?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wzMzczODV8MHwxfHJhbmRvbXx8fHx8fHx8fDE3MjE3NDM4OTh8&ixlib=rb-4.0.3&q=80&w=1080"
                    alt="Foto"
                    left={false}
                    titulo="Ventas efectivas"
                    texto="Aprende a vender más y mejor con técnicas y estrategias para incrementar tus ingresos."
                />

                <Bloque
                    src="https://images.unsplash.com/photo-1695654400763-2d6db6903452?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wzMzczODV8MHwxfHJhbmRvbXx8fHx8fHx8fDE3MjE3NDM4OTh8&ixlib=rb-4.0.3&q=80&w=1080"
                    alt="Foto"
                    titulo="Marketing efectivo"
                    texto="Descubre estrategias de marketing y promoción adaptadas a tu negocio para captar y retener clientes."
                />
                <Bloque
                    src="https://images.unsplash.com/photo-1695653422894-89ec853c31c5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wzMzczODV8MHwxfHJhbmRvbXx8fHx8fHx8fDE3MjE3NDM4OTh8&ixlib=rb-4.0.3&q=80&w=1080"
                    alt="Foto"
                    titulo="Herramientas prácticas"
                    texto="Accede a herramientas prácticas para la gestión diaria de tu negocio, facilitando la toma de decisiones y la operación eficiente."
                    left={false}
                />
            </Box>
            <Box sx={{
                width: "100%",
                height: "400px",
                display: "flex",
                alignItems: "center",
                paddingX: "140px",
                background: "linear-gradient(to right, rgba(206, 164, 24, .9), rgba(206, 164, 24, .7))",
                boxSizing: "border-box",
                marginTop: "100px",
            }}>
                <Typography sx={{
                    fontSize: "30px",
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "white",
                }}>
                    "Tooli ha transformado la forma en que gestiono mi negocio, aumentando mis ventas y mejorando mi marketing. "
                </Typography>
            </Box>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                border: "1px solid black",
                borderRadius: "20px",
                marginX: "40px",
                height: "400px",
                alignItems: "center",
                justifyContent: "center",
                paddingX: "140px",
                gap: "40px",
                marginTop: "100px",
            }}>

                <Typography sx={{
                    fontSize: "40px",
                    fontWeight: "bold",
                    display: "flex",
                }}>
                    Únete a nuestra comunidad.
                </Typography>
                <Typography sx={{
                    fontSize: "24px",
                    textAlign: "center",
                    color: "gray",
                }}>
                    “Gracias a Tooli, he podido implementar estrategias de promoción efectivas que han resultado en un crecimiento significativo para mi negocio.” - Laura Zamora
                </Typography>
                <Box sx={{
                    width: "600px",
                }}>
                    <Inscribirse />
                </Box>
            </Box>

        </Box>
    );
}